export const offerWallModal = 'OFFER_WALL_MODAL_STATUS';
export const offerWallModalDate = 'OFFER_WALL_MODAL_DATE';

export const tonAddressName = 'TON_ADDRESS';
export const rewardTimeStamp = 'REWARD_TIMESTAMP';
export const storageTaps = 'taps';
export const boostTime = 20300;
export const boostInSeconds = 20;
export const tonRate = 5.4;
export const minimalAmountInfo = {usd: tonRate, ton: 1, discount: 0, tokenMultiplier: 0.0102};
export const boostMultiplier = 5;
export const superBoostMultiplier = 10;

export const shareMessage = `🔗 Join to get reward

💎 +100 $AGO

🎨 Design:  Imagine your character
🤖 AI will generate it for you!
🪙 Tokenize (mint) your creation as an NFT. 
🎮 Play, Own and trade your asset within the Hexacore gaming universe.`;
export const firstQuest = [
  {
    goal: '1K',
    goalType: 'TAPS',
    reward: '$AGO',
    rewardCount: 100,
    claimed: true,
    achieved: true,
  }, {
    goal: '10K',
    goalType: 'TAPS',
    reward: '$AGO',
    rewardCount: 300,
    claimed: true,
    achieved: true,
  }, {
    goal: '25K',
    goalType: 'TAPS',
    reward: '$AGO',
    rewardCount: 500,
    claimed: true,
    achieved: true,
  },
];
export const secondQuest = [
  {
    goal: '3',
    goalType: 'd',
    reward: '$AGO',
    rewardCount: 100,
    claimed: true,
    achieved: true,
  },
  {
    goal: '7',
    goalType: 'd',
    reward: '$AGO',
    rewardCount: 100,
    claimed: false,
    achieved: false,
  },
  {
    goal: '14',
    goalType: 'd',
    reward: 'LOOT_BOX',
    rewardCount: 1,
    claimed: false,
    achieved: false,
  },
];

export const games = [
  {
    mainStatus: false,
    name: 'Pin Bullet',
    peopleCount: 282006,
    previewUrl: 'https://cdn-pocketspace-0001.hexacore.io/public/pin%20bullet.mp4',
    status: 'new',
    appId: 2,
    loaderUrl: 'pinbullet/Build.loader.js?v=2.05',
    dataUrl: 'pinbullet/Build.data.unityweb?v=2.05',
    frameworkUrl: 'pinbullet/Build.framework.js.unityweb?v=2.05',
    codeUrl: 'pinbullet/Build.wasm.unityweb?v=2.05',
  },
  {
    mainStatus: false,
    name: 'Dirty job',
    peopleCount: 256852,
    previewUrl: 'https://cdn-pocketspace-0001.hexacore.io/public/dirty%20job.mp4',
    status: 'active',
    loaderUrl: 'dirty-job/Build.loader.js?v=1.22',
    dataUrl: 'dirty-job/Build.data.unityweb?v=1.22',
    frameworkUrl: 'dirty-job/Build.framework.js.unityweb?v=1.22',
    codeUrl: 'dirty-job/Build.wasm.unityweb?v=1.22',
    appId: 3,
  },
  {
    mainStatus: false,
    name: 'Pals',
    peopleCount: 186582,
    previewUrl: 'https://cdn-pocketspace-0001.hexacore.io/public/pals.mp4',
    status: 'active',
    loaderUrl: 'pals/Build.loader.js?v=5.07',
    dataUrl: 'pals/Build.data.unityweb?v=5.07',
    frameworkUrl: 'pals/Build.framework.js.unityweb?v=5.07',
    codeUrl: 'pals/Build.wasm.unityweb?v=5.07',
    appId: 5,
  },
  {
    mainStatus: false,
    name: 'Richie - My Fluffy Mayor',
    // peopleCount: 290652,
    status: 'new',
    previewUrl: 'https://storage.googleapis.com/pocketspace-0001/public/richie-vert.mp4?1',
    externalUrl: 'https://t.me/richie_mayor_bot/gameapp',
    appId: 7,
  },
  {
    mainStatus: false,
    name: 'Hurt me please',
    peopleCount: 321552,
    previewUrl: 'https://storage.googleapis.com/pocketspace-0001/public/hurt_me.mp4',
    status: 'active',
    loaderUrl: 'hurt-me-please/Build.loader.js?v=6.09',
    dataUrl: 'hurt-me-please/Build.data.unityweb?v=6.09',
    frameworkUrl: 'hurt-me-please/Build.framework.js.unityweb?v=6.09',
    codeUrl: 'hurt-me-please/Build.wasm.unityweb?v=6.09',
    appId: 6,
  },
  {
    mainStatus: true,
    name: 'Hexacore Gaming Universe',
    peopleCount: 320551,
    previewUrl: 'https://cdn-pocketspace-0001.hexacore.io/public/assets%20view.mp4',
    status: 'active',
    loaderUrl: 'assets-view/Build.loader.js?v=1.23',
    dataUrl: 'assets-view/Build.data.unityweb?v=1.23',
    frameworkUrl: 'assets-view/Build.framework.js.unityweb?v=1.23',
    codeUrl: 'assets-view/Build.wasm.unityweb?v=1.23',
    appId: 1,
  },
  {
    mainStatus: false,
    name: 'Miner',
    peopleCount: 0,
    previewUrl: 'https://cdn-pocketspace-0001.hexacore.io/public/Miner.mp4',
    status: 'coming',
    url: '#',
  },
  {
    mainStatus: true,
    name: 'Pocket space',
    peopleCount: 0,
    previewUrl: 'https://cdn-pocketspace-0001.hexacore.io/public/pocket_horizontal.mp4',
    status: 'coming',
    url: '#',
  },
  {
    mainStatus: false,
    name: '3D merge animal',
    peopleCount: 0,
    previewUrl: 'https://cdn-pocketspace-0001.hexacore.io/public/merge_animals.mp4',
    status: 'coming',
    url: '#',
  },
  {
    mainStatus: false,
    name: 'DNA evolution',
    peopleCount: 0,
    previewUrl: 'https://cdn-pocketspace-0001.hexacore.io/public/dna.mp4',
    status: 'coming',
    url: '#',
  },
];

export * from './transactions';
export * from './modals';
export * from './routes';
export * from './tabs';
export * from './missions';
export * from './staking';
export * from './level';
